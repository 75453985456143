var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-table-container px-0 mx-0"},[_c('b-table',_vm._g({staticClass:"table-list",attrs:{"responsive":"","fixed":"","no-local-sorting":"","items":_vm.users,"fields":_vm.fields,"sort-by":_vm.queryParams.sortBy,"sort-desc":_vm.queryParams.sortOrder === 'desc'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"head(updated)",fn:function(data){return [_c('div',{staticClass:"light-border-right white-space-pre"},[_c('div',{staticClass:"d-flex column-explanation"},[_c('div',[_c('span',[_vm._v(_vm._s(data.label))]),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"custom-data-label d-flex align-items-start"},[_c('b-avatar',{staticClass:"mr-2",attrs:{"size":20}}),_c('div',[_c('p',{staticClass:"text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(_vm.namesFormatter(item.lastName, item.firstName))+" ")]),_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[_vm._v("ID "+_vm._s(item.userId))])])],1)]}},{key:"cell(email)",fn:function(data){return [_c('div',{staticClass:"overflowWrapAnywhere custom-data-label d-flex align-items-start"},[_vm._v(" "+_vm._s(data.item.email)+" ")])]}},{key:"cell(enabled)",fn:function(ref){
var item = ref.item;
return [_c('b-tooltip',{attrs:{"target":("enabled-" + (item.userId)),"disabled":!_vm.isCurrentUser(item)}},[_vm._v(_vm._s(_vm.t('adminCantDisableOwnAccount')))]),_c('b-row',{attrs:{"id":("enabled-" + (item.userId)),"align-h":"center"}},[(_vm.canEdit)?_c('b-form-checkbox',{attrs:{"disabled":_vm.isCurrentUser(item)},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}}):[_vm._v(" "+_vm._s(item.enabled ? _vm.t('yes') : _vm.t('no'))+" ")]],2)]}},{key:"cell(role)",fn:function(ref){
var item = ref.item;
return [_c('b-tooltip',{attrs:{"target":("roleId-" + (item.userId)),"disabled":!_vm.isCurrentUser(item)}},[_vm._v(_vm._s(_vm.t('cantChangeRoleOwnAccount')))]),(_vm.canEdit)?_c('div',{attrs:{"id":("roleId-" + (item.userId))}},[_c('b-form-select',{staticClass:"text-center",attrs:{"options":_vm.roles,"size":"sm","text-field":"name","value-field":"roleId","disabled":_vm.isCurrentUser(item)},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select")])]},proxy:true}],null,true),model:{value:(item.roleId),callback:function ($$v) {_vm.$set(item, "roleId", $$v)},expression:"item.roleId"}})],1):[_vm._v(" "+_vm._s(_vm.get(item, 'role.name', _vm.t('na')))+" ")]]}},{key:"cell(language)",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('b-form-select',{staticClass:"text-center",attrs:{"options":_vm.languages,"disabled":_vm.isUSUser(item),"size":"sm","text-field":"name","value-field":"code"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select")])]},proxy:true}],null,true),model:{value:(item.language),callback:function ($$v) {_vm.$set(item, "language", $$v)},expression:"item.language"}}):[_vm._v(" "+_vm._s(item.language)+" ")]]}},{key:"cell(decimalSeparator)",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('b-form-select',{staticClass:"text-center w-85px",attrs:{"options":_vm.decimalSeparators,"disabled":_vm.isUSUser(item),"size":"sm"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select")])]},proxy:true}],null,true),model:{value:(item.decimalSeparator),callback:function ($$v) {_vm.$set(item, "decimalSeparator", $$v)},expression:"item.decimalSeparator"}}):[_vm._v(" "+_vm._s(item.decimalSeparator)+" ")]]}},{key:"cell(zones)",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('b-form-select',{staticClass:"text-center w-85px",attrs:{"options":_vm.zones,"size":"sm"},on:{"change":function($event){return _vm.onZonesUserChange(item, $event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select")])]},proxy:true}],null,true),model:{value:(item.zones),callback:function ($$v) {_vm.$set(item, "zones", $$v)},expression:"item.zones"}}):[_vm._v(" "+_vm._s(item.zones)+" ")]]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"type":"button","variant":"primary","disabled":_vm.isRowDefault(item)},on:{"click":function($event){return _vm.updateUser(item)}}},[_vm._v(_vm._s(_vm.t('update')))])],1)]}}])},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }