<template>
    <b-modal :visible="modalShow" @hide="hideSelector" size="lg">
        <b-container>
            <AddEditRoleDialog
                :modalShow.sync="addEditRoleDialog_showModal"
                :roleId="addEditRoleDialog_roleId"
            />

            <h2>{{ t('adminRolesList_Title') }}</h2>

            <b-row class="text-center">
                <b-col lg="4">
                    <h3>{{ t('adminAddRole_Roles') }}</h3>

                    <div id="rolesContainer">
                        <div v-for="role in rolesList" :key="role.name" class="list-group">
                            <a
                                @click="roleClicked(role)"
                                class="rounded-0 list-group-item list-group-item-action list-group-item-light"
                                :class="{
                                    active:
                                        currentRole !== null && role.roleId == currentRole.roleId,
                                }"
                            >
                                {{ role.name }}
                            </a>
                        </div>
                    </div>
                </b-col>
                <b-col lg="8">
                    <template v-if="currentRole">
                        <h3>{{ t('adminAddRole_Permissions') }}</h3>
                        <div v-for="permission in currentRole.permissions" :key="permission.name">
                            <!-- TODO: remove WarningAlert when get deleted in database -->
                            <b-row v-if="permission.accessFeatureCode !== 'WarningAlert'">
                                <b-col cols="7" style="text-align: right">
                                    {{ permission.accessFeatureName }}:
                                </b-col>
                                <b-col cols="4" style="text-align: left">
                                    {{ permission.permissionName }}
                                </b-col>
                            </b-row>
                        </div>

                        <b-button v-if="canEditRoles" @click="editRoleClicked" class="mt-3">
                            {{ t('adminEditRole') }}
                        </b-button>
                    </template>
                </b-col>
            </b-row>
        </b-container>

        <template #modal-footer="{ok}">
            <b-button v-if="canEditRoles" @click="addRole" class="mr-auto">
                {{ t('adminAddRole') }}
            </b-button>

            <b-button @click="ok()" variant="primary">{{ t('ok') }}</b-button>
        </template>
    </b-modal>
</template>
<script>
import {mapGetters} from 'vuex';
import AddEditRoleDialog from '@/views/admin/components/AddEditRoleDialog.vue';

export default {
    name: 'RolesDialog',
    components: {
        AddEditRoleDialog,
    },
    props: {
        modalShow: {
            type: Boolean,
            required: true,
        },
        canEditRoles: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            currentRole: null,
            addEditRoleDialog_roleId: null,
            addEditRoleDialog_showModal: false,
        };
    },
    computed: {
        ...mapGetters({
            rolesList: 'roles/list',
        }),
    },
    methods: {
        addRole() {
            this.addEditRoleDialog_roleId = null;
            this.addEditRoleDialog_showModal = true;
        },
        editRoleClicked() {
            this.addEditRoleDialog_roleId = this.currentRole.roleId;
            this.addEditRoleDialog_showModal = true;
        },
        hideSelector() {
            this.$emit('update:modalShow', false);
        },
        async loadRolesList() {
            await this.blockingRequest('roles/fetchList');

            if (this.rolesList == undefined) {
                alert('rolesList is undefined');
                return;
            }

            if (this.rolesList.length > 0) this.currentRole = this.rolesList[0];

            document.getElementById('rolesContainer').scrollTop = 0;
        },
        roleClicked(role) {
            this.currentRole = role;
        },
    },
    watch: {
        async addEditRoleDialog_showModal(value) {
            //If the addEditRoleDialog is closed then reload the roles
            if (!value) {
                this.loadRolesList();
            }
        },
        async modalShow(value) {
            if (value) {
                this.loadRolesList();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
#rolesContainer {
    max-height: 300px;
    overflow: auto;
}
</style>
