<template>
    <b-modal :visible="modalShow" @hide="hideSelector">
        <b-container>
            <template v-if="roleIdNotFound">
                <h2>Error: RoleID not found.</h2>
            </template>
            <template v-else>
                <h2>{{ editMode ? t('adminEditRole') : t('adminAddRole') }}</h2>

                <b-form @submit.stop.prevent="onSubmit" novalidate>
                    <b-form-group
                        v-show="!editMode"
                        :label="t('adminRoleName')"
                        label-for="input-role-name"
                        :invalid-feedback="roleNameFeedback"
                    >
                        <b-form-input
                            id="input-role-name"
                            v-model="$v.form.roleName.$model"
                            :state="validateState('roleName')"
                            required
                            :disabled="submitting || editMode"
                            :placeholder="t('adminRoleNamePlaceholder')"
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>

                    <h3 v-if="editMode">{{ t('adminRoleName') }}: {{ $v.form.roleName.$model }}</h3>

                    <b-form-group
                        :label="t('adminAddRole_Permissions')"
                        label-for="permissions-group"
                        class="mt-3"
                    >
                        <b-row class="text-center">
                            <b-col
                                v-for="permission in permissions"
                                :key="permission.name"
                                cols="6"
                                class="mr-auto ml-auto pt-1 pb-1"
                                id="permissions-group"
                            >
                                <label class="pb-1">{{ permission.accessFeatureName }}</label>
                                <b-form-select
                                    v-model="permission.selected"
                                    :disabled="submitting"
                                    :options="permission.permissionOptions"
                                ></b-form-select>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </template>
        </b-container>

        <template #modal-footer="{cancel}">
            <b-button
                @click="onSubmit()"
                :disabled="submitting || !hasAllPermission"
                variant="primary"
                class="ml-auto"
            >
                {{ t('save') }}
            </b-button>
            <b-button @click="cancel()" variant="secondary">
                {{ t('cancel') }}
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import {mapState} from 'vuex';

export default {
    name: 'AddEditRoleDialog',
    components: {},
    props: {
        modalShow: {
            type: Boolean,
            required: true,
        },
        roleId: {
            type: Number,
            default: null,
        },
    },
    validations: {
        form: {
            roleName: {
                required,
            },
        },
    },
    data() {
        return {
            currentRole: null,
            form: {
                roleName: null,
            },
            permissions: [],
            roleIdNotFound: false,
            submitAttempted: false,
            submitting: false,
        };
    },
    computed: {
        ...mapState({
            permissionsList: (state) =>
                //  TODO: remove WarningAlert when get deleted in database
                (state.permissions.list || []).filter(
                    (permission) => permission.accessFeatureCode !== 'WarningAlert'
                ),
            rolesList: (state) => state.roles.list,
        }),
        hasAllPermission() {
            return this.permissions.every((x) => x.selected);
        },
        editMode() {
            return this.roleId !== null;
        },
        roleNameFeedback() {
            if (!this.submitAttempted) return '';

            if (!this.$v.form.roleName.$model?.length) {
                return this.t('adminAddRoleNameError');
            }

            if (!this.checkRoleName()) {
                return this.t('adminAddRoleNameAlreadyUsedError');
            }

            return '';
        },
    },
    methods: {
        checkRoleName() {
            if (
                !this.$v.form.roleName.$model?.length ||
                this.rolesList.find(
                    (x) => x.name.toLowerCase() === this.form.roleName.toLowerCase()
                )
            )
                return false;

            return true;
        },
        clearDialog() {
            this.$v.form.roleName.$model = '';
            this.permissions.forEach((x) => (x.selected = 0));
        },
        hideSelector() {
            this.clearDialog();
            this.$emit('update:modalShow', false);
        },
        loadPermissionsIntoComboBoxes() {
            let output = [];

            for (var i = 0; i < this.permissionsList.length; i++) {
                let permission = this.permissionsList[i];

                //Check to see if the permission has already been added
                let item = output.find(
                    (elem) => elem.accessFeatureId == permission.accessFeatureId
                );

                if (!item) {
                    item = {
                        accessFeatureId: permission?.accessFeatureId,
                        accessFeatureName: permission?.accessFeatureName,
                        accessFeatureCode: permission?.accessFeatureCode,
                        selected: 0,
                        permissionOptions: [
                            {
                                value: 0, //Add a blank elem. Will be removed on post/put
                                text: '',
                            },
                        ],
                    };

                    output.push(item);
                }

                //Add the permission to output
                item.permissionOptions.push({
                    value: permission.permissionId,
                    text: permission.permissionName,
                    permissionCode: permission.permissionCode,
                    permissionId: permission.permissionId,
                    permissionName: permission.permissionName,
                });
            }

            this.permissions = output;
        },
        loadRole() {
            //Load role if we're in Edit Mode
            if (this.editMode) {
                let role = this.rolesList.find((x) => x.roleId == this.roleId);

                if (!role) {
                    //Role not found
                    this.roleIdNotFound = true;
                } else {
                    //Role found
                    this.currentRole = role;
                    this.roleIdNotFound = false;
                    this.$v.form.roleName.$model = role.name;

                    role.permissions.forEach((rolePerm) => {
                        //find the accessFeatureId in the permissions arr
                        let permListItem = this.permissions.find(
                            (perm) => perm.accessFeatureId == rolePerm.accessFeatureId
                        );

                        if (!permListItem) {
                            return;
                        }

                        let permOption = permListItem.permissionOptions.find(
                            (permOption) => permOption.permissionId == rolePerm.permissionId
                        );

                        permListItem.selected = permOption.value;
                    });
                }
            } else {
                //We're in Add mode so clear form
                this.clearDialog();
            }
        },
        async onSubmit(event) {
            if (!this.submitting) {
                this.submitAttempted = true;
                this.submitting = true;

                this.$v.form.$touch();
                if (
                    this.$v.form.$anyError ||
                    (!this.editMode && !this.checkRoleName()) //Only check the roleName if in addMode
                ) {
                    this.submitting = false;
                    return;
                }

                let roleUpload = {
                    name: this.form.roleName,
                    permissions: [],
                };

                if (this.editMode) {
                    roleUpload.roleId = this.currentRole.roleId;
                }

                this.permissions.forEach((perm) => {
                    if (!perm.selected) return;

                    roleUpload.permissions.push({
                        accessFeatureId: perm.accessFeatureId,
                        permissionId: perm.selected,
                    });
                });

                try {
                    await this.blockingRequest('roles/addRole', {
                        editMode: this.editMode,
                        roleData: roleUpload,
                    });
                } catch (error) {
                    alert(`addRole failed:${error}`);
                }

                this.submitting = false;
                this.hideSelector();
            }
        },
        validateState(name) {
            if (!this.submitAttempted) return;

            return this.checkRoleName();
        },
    },
    watch: {
        async modalShow(value) {
            if (value) {
                this.submitting = false;
                this.submitAttempted = false;

                await this.blockingRequest('permissions/fetchList');
                this.loadPermissionsIntoComboBoxes();

                await this.blockingRequest('roles/fetchList');
                this.loadRole();
            }
        },
    },
};
</script>
